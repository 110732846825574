.gb0 {
    transform-origin: 0% 0% 0%;
    transform: rotate(0deg)
}

.gb90 {
    transform-origin: left top ;

    transform: rotate(90deg)
    
}

.gb180 {
    transform-origin: 100% 100% 0%;
    transform: rotate(180deg)
}

.gb270 {
    transform-origin: left  top ;
    transform: rotate(270deg)
}

.gbText{
    flex-wrap: wrap;
    text-align: center;
    font-weight: 200;
    text-transform: uppercase;
    text-indent: 3%
}

.groupboxeditor:hover{
    background-color: #eeeeee
}