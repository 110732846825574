body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #ffffff;
}
.debuginfo{
  display: none;
  position: fixed;
  min-height: 100px;
  min-width: 100px;
  background-color: #ffffff88;
  top:0;
  left:0;
  z-index: 1000;
  border: solid 1px red;
}     


.layoutBorders {
  /* border:dashed 1px blue; */
}
.component{
  float: left;
  position: absolute;
  width: 1470px;
  transform-origin-y: 0px; 
  transform-origin-x: 0px;
}
#sb {
  /* display: none; */
}


.mastheadlogo {
    margin:6px;
    margin-left:6px;
    margin-top:10px;
    width:auto;
    height:20px;
}
.mastheadlogo-mobile {
    margin:6px;
    margin-left:36px;
    margin-top:10px;
    width:auto;
    height:20px;
}

.mastheadRow {
    max-height: 30px;
    overflow:clip
}


.buyme{
    xdisplay: none;
    position: absolute;
    top: 32px;
    left:42px;
    width: 660px;
    height:400px;
    padding:32px;
    background-color: #3D2985;
    color: #ffffff; 
    z-index: 10000;
    box-shadow: 10px 10px  rgba(0, 0, 0, .5);

}

.headContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height:40px;
    max-height: 40px;
    xborder:1px solid red
}

.headLeft {

    
    -webkit-align-self: flex-start;

    
            align-self: flex-start
}
.headCenter {
    -webkit-flex-grow:2;
            flex-grow:2;
    -webkit-align-self: center;
            align-self: center;
    text-align: center

}

.headRight {
    
    -webkit-align-self: flex-end;
    
            align-self: flex-end;
    margin-right: 16px;
}
.messagedialog {
  margin-left: -8px;
  margin-right: -18px;
}
.feedbackIcon{
position: fixed;
top: 5px;
right:5px;

}
body{
  xoverflow: hidden;
  }
.buttom .notify
{
  display:none;
}

.lefter .notify
{
  display:none;
}

.service .notify
{
  display:none;
  position:absolute;
  z-index:10;
  background-color: red;
  border: solid;
  border-width:0px;
  border-color:white;
  margin-left:70px; 
  margin-top:-15px; 
  height:20px;
  width:20px;
  border-radius:50%;
  text-align:center;
  line-height:20px;
   }
.connect-presentation{
  position: absolute;
  margin-left:750px;
  margin-top:40px;
  z-index:-10;
  width:90px;
  background-color:#BBBBE6;
  border: solid;
  border-color:#B0B0D9;
  border-width:3px;
  height:20px;
}

.connect-enterprise-video{
  position: absolute;
  margin-left:50px;
  margin-top:40px;
  z-index:-10;
  width:150px;
  height:20px;
  border: solid;
  background-color:#A6E1F4;
  border-color:#74C5DE;
  border-width:3px;
}
.connect-office-online{
  position: absolute;
  margin-left:450px;
  margin-top:40px;
  z-index:-10;
  width:380px;
  height:20px;
  background-color:#A6E1F4;
  border: solid;
  border-color:#74C5DE;
  border-width:3px;
}
.connect-presentation-down{
  position: absolute;
  margin-left:740px;
  margin-top:90px;
  z-index:-10;
  width:20px;
  height:20px;
  background-color:#BBBBE6;
  border: solid;
  border-color:#B0B0D9;
  border-width:3px;
}

.connect-employee-profile-down{
  position: absolute;
  margin-left:295px;
  margin-top:90px;
  z-index:-10;
  width:20px;
  height:20px;
  background-color:#BBBBE6;
  border: solid;
  border-color:#9898CE;
  border-width:3px;
}

.connect-filestorage{
  position: absolute;
  margin-left:50px;
  margin-top:40px;
  z-index:-10;
  width:280px;
  background-color:#C2EBBA;
  border: solid;
  border-color:#A6DD9C;
  border-width:3px;
  height:20px;
}
.connect-forms{
  position: absolute;
  margin-left:380px;
  margin-top:40px;
  z-index:-10;
  width:150px;
  background-color:#BBBBE6;
  border: solid;
  border-color:#9898CE;
  border-width:3px;
  height:20px;
}

.connect-business-apps{
  position: absolute;
  margin-left:550px;
  margin-top:40px;
  z-index:-10;
  width:260px;
  background-color:#C1ECBE;
  border: solid;
  border-color:#9DDA91;
  border-width:3px;
  height:20px;
}

.connect-outlook{
  position: absolute;
  margin-left:50px;
  margin-top:40px;
  z-index:-10;
  width:400px;
  background-color:#B8E7F6;
  border: solid;
  border-color:#74C5DE;
  border-width:3px;
  height:20px;
}
.connect-task-management{
  position: absolute;
  margin-left:440px;
  margin-top:40px;
  z-index:-10;
  width:200px;
  background-color:#C2EBBA;
  border: solid;
  border-color:#95D689;
  border-width:3px;
  height:20px;
}
.connect-project-management{
  position: absolute;
  margin-left:650px;
  margin-top:40px;
  z-index:-10;
  width:190px;
  background-color:#C9C9EB;
  border: solid;
  border-color:#ADADD9;
  border-width:3px;
  height:20px;
}
.connect-direct-communication-1{
  position: absolute;
  margin-left:55px;
  margin-top: 0px;
  z-index:-20;
  width:100px;
  background-color:#D0D0D0;
  height:100px;
}
.connect-chat{
  position: absolute;
  margin-left:50px;
  margin-top:40px;
  z-index:-5;
  width:190px;
  background-color:#C9C9EB;
  border: solid;
  border-color:#9E9ED1;
  border-width:3px;
  height:20px;
}
.connect-social-network{
  position: absolute;
  margin-left:340px;
  margin-top:40px;
  z-index:-10;
  width:130px;
  background-color:#B8E7F6;
  border: solid;
  border-color:#74C5DE;
  border-width:3px;
  height:20px;
}
.connect-small-business{
  position: absolute;
  margin-left:650px;
  margin-top:40px;
  z-index:-10;
  width:190px;
  background-color:#B3E6A9;
  border: solid;
  border-color:#9CD991;
  border-width:3px;
  height:20px;
}

.connect-direct-communication-2{
  position: absolute;
  margin-left:55px;
  margin-top: 0px;
  z-index:-20;
  width:400px;
  background-color:#D0D0D0;
  height:100px;
}



.pad{
    xwidth:860px;
   /*
    background-color:grey;
    border:solid 1px red
    */
  }
  .row {
    xbackground-color: red;
    clear: both;
  }
  .icon {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    text-align: center;
    font-size: 24px;
  }
  .heading {
    position: absolute;

    width: 75px;
  }

  .subtitle
  {
    position: absolute;

    bottom:1;
    margin-top:14px;
  }

  .footer {
    margin-top: 25px;
    bottom: 1;
  }

  .col {
    cursor: hand;
    background-color: white;
    float: left;
    height: 80px;
    margin: 5px;
    padding: 5px;
    color: white;
    page-break-after: 3px;
  }
  .service {
    width: 80px;
  }
  .hidde {
    opacity: 0;
  }
  .leftcol {
    color: black;
    height: 80px;
    width: 40px;
    cursor: hand;
    background-color: white;
    float: left;
    margin-top: 5px;
    margin-left: 5px;
    padding: 5px;
    color: white;
    page-break-after: 3px;
    text-align: center;
  }
  .lefttext {
    color: black;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-transform-origin: 46% 50%;
            transform-origin: 46% 50%;
    text-transform:uppercase;
    width:78px;
    height:78px;
    xborder:solid 1px red;
  }

  .rightcol {
    color: black;
    height: 80px;
    width: 40px;
    cursor: hand;
    background-color: white;
    float: left;
    margin: 5px;
    padding: 5px;
    color: white;
    page-break-after: 3px;
  }

  .righttext {
    color: black;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 25% 60%;
            transform-origin: 25% 60%;
    text-transform:uppercase;
    width:78px;
    height:38px;
    text-align: center;
  }
  .buttom {
    margin-top: 50px;
    height: 35px;
    width: 80px;
    color: black;
   text-transform:uppercase;
    text-align: center;
  }

  .buttom4 {
    clear:both;
    margin-left:55px;
    margin-top: 50px;
    height: 55px;
    width: 400px;
    color: #EFEFEF;
    background-color:#7C7C7C;
     text-transform:uppercase;
  line-height: 45px;
    text-align: center;
  }

  .lefter {

    width:25px;
  }

  .subbottom{

  }
  .sub270{
     color: black;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-transform-origin: 46% 50%;
            transform-origin: 46% 50%;
    text-transform:uppercase;
    width:78px;
    height:78px;
      text-align: center;


  }

  .sub90{
     color: black;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 20% 40%;
            transform-origin: 20% 40%;
    text-transform:uppercase;
    width:78px;
    height:38px;
      text-align: center;


  }

  .App {
    text-align: center;
  }
  
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 80px;
  }
  
  .App-header {
    background-color: #222;
    height: 40px;
    padding: 20px;
    color: white;
  }
  
  .App-title {
    margin-top:20px;
    font-size: 15px;
  }
  
  .App-intro {
    font-size: large;
  }
  
  @-webkit-keyframes App-logo-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    16% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
    }
    33% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  
    50% {
      -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg);
    }
    80% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    90% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    
    /* from { transform: rotate(-20deg); }
    to { transform: rotate(20deg); } */
  }
  
  @keyframes App-logo-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    16% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
    }
    33% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  
    50% {
      -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg);
    }
    80% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    90% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    
    /* from { transform: rotate(-20deg); }
    to { transform: rotate(20deg); } */
  }
  
  .ms-NavExample-LeftPane {
    width: 208px;
    height: 500px;
    box-sizing: border-box;
    border: 1px solid #EEE;
    overflow-y: auto;
  }

  .ActionCommandBar{
    z-index: 10000;
    position:relative
  }

  .fade-enter {
    opacity: 0.01;
  }
  
  .fade-enter-active {
    opacity: 1;
    -webkit-transition: opacity 5000ms ease-in;
    transition: opacity 5000ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0.01;
    -webkit-transition: opacity 5000ms ease-in;
    transition: opacity 5000ms ease-in;
  }
  .markdownimage{
    height:auto;
    max-width: 80%;
  }
  .toptoolsLeft {
    float: left;
    margin:7px;
  }

  .toptoolsRight {
    float: right;
    margin:7px;
  }
  .SearchBoxHeading{
    
    background-color: #ffffff;
  }

  .markdown table{
    
  }

  .markdown thead {
   
   text-align: left;
   border-bottom: 1px solid lightgray
  }
  .markdown tr {
   
    
    border-bottom: 1px solid lightgray
   }
   .markdown th,
   .markdown td{
    padding: 12px;
    border-bottom: 1px solid lightgray
  }

  
.FontSegoeWhite{
    font-family: "Segoe UI Web (West European)",Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;
    color: #ffffff;
}

.hashedtile:hover {
    opacity:  1
}
.hashedtile:hover {
    opacity:  0.2;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
}

.campaign{
    display: none
}
.countDown{
    
}
.HoverContainer{
    background-color: #ffffffaa;
}

.HoverLink{
    padding:8px;
    cursor: pointer;
    color:#000000;
    border:1px solid #ffffff;
}

.HoverLink:hover{
    color: #ffffff;
    background-color:#000000;
    border:1px solid #000000
}
.gb0 {
    -webkit-transform-origin: 0% 0% 0%;
            transform-origin: 0% 0% 0%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
}

.gb90 {
    -webkit-transform-origin: left top ;
            transform-origin: left top ;

    -webkit-transform: rotate(90deg);

            transform: rotate(90deg)
    
}

.gb180 {
    -webkit-transform-origin: 100% 100% 0%;
            transform-origin: 100% 100% 0%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg)
}

.gb270 {
    -webkit-transform-origin: left  top ;
            transform-origin: left  top ;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg)
}

.gbText{
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    text-align: center;
    font-weight: 200;
    text-transform: uppercase;
    text-indent: 3%
}

.groupboxeditor:hover{
    background-color: #eeeeee
}
.groupDimmed {

    -webkit-filter: blur(2px);

            filter: blur(2px);
}
.tableproperties:hover{
    border: 1px dashed #e8e8e8;
    background-color: aqua 
}
.wizardContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height:36px;
    max-height: 36px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 4px;
    xborder:1px solid red
}

.wizardRight {

    xborder:1px solid blue;
    -webkit-align-self: flex-start;
            align-self: flex-start
}
.wizardCenter {
    xborder:1px solid yellow;
    -webkit-flex-grow:2;
            flex-grow:2;
    -webkit-align-self: center;
            align-self: center

}

.wizardLeft {
    xbackground-color: aqua;
    xborder:1px solid green;
    cursor: progress;
    width:16px;
    height:32px;
    -webkit-align-self: flex-end;
            align-self: flex-end;

}
.wizardLeft:hover {
    border:1px solid green;
    
    
}


.prompter {
    padding:8px;
    color:#999999;
}

.prompter:hover {

    background-color: #444444;
  
    color:#ffffff;
}

.ml6 {
    position: relative;
    font-weight: 900;
    font-size: 1.8em;
  }
  
  .ml6 .text-wrapper {
    position: relative;
    display: inline-block;
    padding-top: 0.2em;
    padding-right: 0.05em;
    padding-bottom: 0.1em;
    overflow: hidden;
  }
  
  .ml6 .letter {
    display: inline-block;
    line-height: 1em;
  }
  .toolbody  img {
    max-width: 100%;
    height: auto;
}
.pivotGlobal {
  background-color: #ffffff;
}
.pivotGlobal:hover {
    background-color: #f4f4f4;
  }
  
.TableCommandBar{
    background-color: red !important;
}
.ms-CommandBarSearch-input  {

    cursor: text !important;
}

.bluecommands {
    background-color: #0B559F;
    color: #ffffff;
}


.NavContext {
    border-bottom: 1px solid #cccccc;
    height: 41px;
    width: 100%;
}
.NavLeft {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: #e7e7e7; 

    padding:8px;
   

}

.NavLeftGroup {
    background-color: #e7e7e7
}


.NavLeftItem {
    border-left: 3px solid #cfcfcf;    

    padding-left:8px;
    margin-left:-8px;
}

.NavLeftItem:hover {
    
    border-left: 3px solid blue;    

    padding-left:8px;
    margin-left:-8px;
}


.NavLeftItemActive {
    color: #333333 !important;
    background-color: #e7e7e7 
    
}

.NavLeftExpanded{
min-width: 280px;
height:100%;
background-color: #e7e7e7; 
}

.NavLeftCollapsed{
    max-width: 93px;
    background-color: #e7e7e7; 
    height:100%;
}

.NavLeftSettings {
    padding-top:10px;
    border-top: 1px solid #cccccc;
    background-color: #e7e7e7;
    bottom:0px;
    
    position: absolute;
}


.NavTogglerExpanded{
    margin-top:-25px;
    margin-left:250px;
    cursor: pointer;
    float: left;
}

.NavTogglerCollapsed{
margin-top:10px;
    margin-left: 13px;
    margin-bottom: 10px
}

